// ! ---------- FONTS


$font-untitled: 'UntitledSans';
$font-icons: 'GGIcons';
$font-stack-default: $font-untitled, Arial, sans-serif;
$font-stack-fallback: Arial, sans-serif;

@include font-face($font-untitled, normal, normal, 'UntitledSansWeb-Regular');
@include font-face($font-untitled, normal, italic, 'UntitledSansWeb-RegularItalic');
@include font-face($font-untitled, bold, normal, 'UntitledSansWeb-Medium');
@include font-face($font-untitled, bold, italic, 'UntitledSansWeb-MediumItalic');

@include font-face($font-icons, normal, normal, 'fontello');


// ! ---------- TYPOGRAPHY

/* critical:start */

%basic-typography {
    font-family: $font-stack-default;
    font-weight: normal;
    letter-spacing: $letterspacing-base; }

/* critical:end */

%header-typography {
    font-size: $fontsize-s-m;
    letter-spacing: -.02em;

    @include breakpoint(380) {
        font-size: $fontsize-m; }

    @include breakpoint(small) {
        font-size: $fontsize-l; }

    @include breakpoint(medium) {
        font-size: $fontsize-xl; } }

%tabular-numerals {
    font-feature-settings: 'tnum';
    font-variant-numeric: tabular-nums; }

%proportional-numerals {
    font-feature-settings: inherit;
    font-variant-numeric: inherit; }

// ! ---------- BUTTONS

$button-shadow-width: .5px;
$button-shadow: 0 0 0 $button-shadow-width inset;
$button-border-width: 1px;
$button-border-width--safari: 1.5px;
$button-border: $button-border-width solid palette('text');
$button-margin: .35em;

%button-base {
    padding: .15em .4em .15em .4em;
    border-radius: .25em;
    border: $button-border;
    border-color: currentColor;
    // border: .06em solid
    transition: transition(color background box-shadow transform opacity, $factor: 2, $easing: ease-out);
    // line-height: 1
    box-shadow: $button-shadow;
    user-select: none;
    cursor: pointer;
    outline: none;

    .safari & {
        border-width: $button-border-width--safari; } }

%button {
    @extend %button-base;

    [data-whatintent="mouse"] & {
        // transform: scale3d(1, 1, 1) translateZ(0)
        backface-visibility: hidden;
        -webkit-filter: blur(.0px); }

    margin-right: $button-margin;
    &:last-child {
        margin-right: 0; }

    &.is-active {
        background: palette('text');
        color: palette('background') !important;
        box-shadow: none; } }

%switch {
    @extend %neutral-button;
    @extend %button-base;
    @extend %button--large;
    padding-left: 1em !important;
    padding-right: 1em !important;
    border-color: var(--accent-color);

    &.is-active {
        color: palette('background') !important;
        box-shadow: none; } }

%switch--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

%switch--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.switch {
    @extend %switch;
    @extend %button--accent; }

.switch--left {
    @extend %switch--left; }

.switch--right {
    @extend %switch--right; }

%button--full-hover {
    .full-color-overlay & {
        border-color: var(--overlay-text-color); }
    &:hover {
        background: palette('text');
        color: palette('background') !important;
        box-shadow: none;
        .full-color-overlay & {
            color: var(--accent-color) !important;
            background: var(--overlay-text-color); } } }

%button--soft-hover {
    @include layout--map-always-visible {
        [data-whatintent="mouse"] &:hover {
            transform: scale3d(1.05, 1.05, 1) translateZ(0);
            transition-timing-function: ease-in;
            transition-duration: $transition-duration * 3/4; } }

    [data-whatintent="mouse"] &.is-active:hover {
        background: palette('text');
        background: var(--category-color);
        border-color: palette('text');
        border-color: var(--category-color);
        color: palette('background') !important;
        box-shadow: none; } }


%button--accent {
    border-color: var(--accent-color);
    color: var(--accent-color);
    &.is-active {
        background: var(--accent-color); } }

%button--highlighted {
    background: palette('text');
    color: palette('background');
    border-color: palette('text');
    box-shadow: none;
    .full-color-overlay & {
        background: var(--overlay-text-color);
        border-color: var(--overlay-text-color); } }

%button--small {
    text-transform: lowercase; }

%button--large {
    padding: .2em .5em .25em .5em; }

%overlay-shadow-left {
    box-shadow: -.2em 0 .25em rgba(palette('text'), 0.2); }

%overlay-shadow-right {
    box-shadow: .2em 0 .25em rgba(palette('text'), 0.2); }

@mixin overlay-shadow-left-right {
    box-shadow: -.2em 0 .25em rgba(palette('text'), 0.2), .2em 0 .25em rgba(palette('text'), 0.2); }

%overlay-shadow-left-right {
    @include overlay-shadow-left-right; }

%overlay-shadow {
    @extend %overlay-shadow-left; }

%close-button {
    @extend %neutral-button;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.2em;
    height: 2.2em;
    @include grid-gutter-margin(top left right bottom, $factor: 1/2);
    transform: scale(0.75);
    transform-origin: 100% 0%;
    span {
        @extend %hide-visually; }

    &::before,
    &::after {
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: currentColor; }
    &::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
    &::after {
        transform: translate(-50%, -50%) rotate(45deg); } }

%arrow-button {
    @extend %neutral-button;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 2.4em;
    height: 2.4em;
    @include grid-gutter-margin(top left right bottom, $factor: 1/2);
    transform: translateX(-50%) translateY(-12%) rotate(315deg) scale(0.75);
    overflow: hidden;
    span {
        @extend %hide-visually; }

    &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        border: 3px solid;

        .safari & {
            border: none;
            box-shadow: 0 0 0 3px currentColor inset; } } }

%arrow-button--right {
    left: 1.5em !important;
    transform:  translateY(-12%) rotate(135deg) scale(0.75); }

$result-group-margin: $one-line-height * 1.5;

%result-item-margin {
    margin-bottom: $one-line-height * .65; }

%result-item-margin--thin {
    margin-bottom: $one-line-height * .45; }




// ! ---------- MEDIA QUERIES


// Save breakpoints to meta tag using Foundation utility method
// Read back in via JS

/* critical:start */

.js-breakpoints {
    font-family: '#{-zf-bp-serialize($breakpoints)}'; }

/* critical:end */


// ! ---------- BASIC STYLES

/* critical:start */

html {
    @extend %basic-typography;
    box-sizing: border-box;
    font-size: $fontsize-base;

    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;

    --accent-color: #{palette('accent')};
    --category-color: #{palette('text')};
    --overlay-text-color: #{palette('text-overlay')};
    --vh-offset: 0px; }

*,
*::before,
*::after {
    box-sizing: inherit; }

body {
    background: palette('background');
    color: palette('text');
    font-size: $fontsize-m;
    line-height: $lineheight-base;

    @if $font-smoothing {
        -webkit-font-smoothing:  antialiased;
        -moz-osx-font-smoothing: grayscale; } }

/* critical:end */

a {
    color: inherit;
    text-decoration: none;
    transition: transition(color);
    outline: none;

    &:hover, &.hover {
        text-decoration: none;
        color: inherit; } }

img {
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic; }

input,
td {
    @extend %basic-typography;
    font-size: 1em; }

input::placeholder,
textarea::placeholder, {
    line-height: normal; }

select {
    width: 100%; }

.hide-visually,
.sr-only {
    @extend %hide-visually; }

.hide-text {
    @extend %hide-text; }

// Show/hide elements in JavaScript

.is-visible {
    display: block !important; }

.is-hidden {
    display: none !important; }

.loading,
.loading input,
.loading a {
    cursor: wait !important; }

// Highlight text

.highlight {
    color: var(--accent-color); }




// ! ---------- TEXT SELECTION


::selection {
    background: palette('selection'); }

::-moz-selection {
    background: palette('selection'); }



// ! ---------- IMAGE LAZYLOADING


// Fade in after load

.lazyload,
.lazyloading {
    opacity: 0; }

.lazyloaded {
    opacity: 1;
    transition: transition(opacity); }


// Blur lazyloaded images before unveiling

.lazyloadblur {
    opacity: 1;
    filter: blur(20px);
    transition: transition(filter);

    &.lazyloaded {
        filter: blur(0); } }


// Hide lazyload images on non JS browsers

.no-js .lazyload {
    display: none !important; }

.no-js noscript .lazyload {
    display: block !important;
    opacity: 1; }



// ! ---------- FLOATING


.clearfix {
    @include clearfix; }

.show-if-overlay {
    @include layout--map-always-visible {
        display: none; } }

// ! ---------- INTRINSIC RATIOS


.ratio-container {
    position: relative;
    height: 0;
    max-width: 100%;
    overflow: hidden;

    img, video, iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }

.ratio-1-1 {
    padding-bottom: 100%; }
.ratio-3-2 {
    padding-bottom: 66.6%; }
.ratio-4-3 {
    padding-bottom: 75%; }
.ratio-5-3 {
    padding-bottom: 60%; }
.ratio-5-4 {
    padding-bottom: 80%; }
.ratio-16-9 {
    padding-bottom: 56.25%; }
.ratio-16-10,
.ratio-8-5 {
    padding-bottom: 62.5%; }



// ! ---------- TEXT BLOCKS


.text-block {

    a, a:hover {}


    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: $fontsize-l;
        margin-bottom: $one-line-height--absolute; }

    p, ul, ol, figure, img, table, address, .TextformatterVideoEmbed {
        margin-bottom: $one-line-height;
        &:last-child {
            margin-bottom: 0; } }

    figure img {
        margin-bottom: 0; }

    ul {
        display: table;
        list-style: none;

        > li {
            display: table-row;

            &::before {
                display: table-cell;
                padding-right: .5em;
                content: "•"; } } }

    ol {
        display: table;
        list-style: none;
        counter-reset: table-ol;

        > li {
            display: table-row;
            counter-increment: table-ol;

            &::before {
                display: table-cell;
                text-align: right;
                padding-right: .4em;
                content: counter(table-ol) "."; } } } }



// ! ---------- ICONS


%icon {
    font-family: $font-icons;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;

    transition: transition(transform);

    /* font-size: 120%; */

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }


[class^="icon-"],
[class*=" icon-"] {
    &::before {
        @extend %icon; } }


.icon-clock:before {
    content: '\E801'; }

.icon-directions:before {
    content: '\E802'; }

.icon-arrow:before {
    content: '\E804'; }

.icon-rotate:before {
    content: '\E803'; }
