$scrollbar-width: 8px !default;

$scrollbar-track-color: transparent !default;
$scrollbar-track-color-hover: transparent !default;
$scrollbar-track-color-active: transparent !default;
$scrollbar-track-border: none !default;
$scrollbar-track-border-radius: 0px !default;

$scrollbar-thumb-color: palette('scrollbar') !default;
$scrollbar-thumb-color-hover:  palette('scrollbar-active') !default;
$scrollbar-thumb-color-active:  palette('scrollbar-active') !default;
$scrollbar-thumb-border: 2px solid transparent !default;
$scrollbar-thumb-border-radius: 4px !default;

.no-hiddenscroll {

    ::-webkit-scrollbar {
        width: $scrollbar-width;
        height: $scrollbar-width; }

    ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px; }

    ::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-color;
        border: $scrollbar-thumb-border;
        border-radius: $scrollbar-thumb-border-radius; }

    ::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-thumb-color-hover; }

    ::-webkit-scrollbar-thumb:active {
        background: $scrollbar-thumb-color-active; }

    ::-webkit-scrollbar-track {
        background: $scrollbar-track-color;
        border: $scrollbar-track-border;
        border-radius: $scrollbar-track-border-radius; }

    ::-webkit-scrollbar-track:hover {
        background: $scrollbar-track-color-hover; }

    ::-webkit-scrollbar-track:active {
        background: $scrollbar-track-color-active; }

    ::-webkit-scrollbar-corner {
        background: transparent; } }
