input,
textarea,
select {
    border: none;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 0;
    &:focus {
        outline: none; } }

// Placeholders: general

%placeholder {
    color: palette('text-lighter');
    transition: transition(color);
    opacity: 1; }

input::placeholder {
    @extend %placeholder; }

@if false {

    ::-webkit-input-placeholder {
        @extend %placeholder; }
    ::-moz-placeholder {
        @extend %placeholder; }
     :-ms-input-placeholder {
        @extend %placeholder; } }


// ! ---------- BUTTONS


.button {
    @extend %neutral-button;
    @extend %button; }

.button--accent {
    @extend %button--accent; }


// ! ---------- BUTTONS


.select-wrapper {
    position: relative;
    @extend %button-base;
    padding: 0;
    margin-right: $button-margin;
    &:last-child {
        margin-right: 0; } }

.select-label {
    @extend %button-base;
    border-width: 0 !important;
    box-shadow: none !important; }

.select {
    @extend %neutral-button;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; }



// ! ---------- LOGO


.logo {}

.logo__text {
    @extend %hide-visually; }

.logo__image {

    svg {
        @include layout--map-as-overlay {
            max-height: 1.7em;
            @include breakpoint(380) {
                max-height: 2em; } } }

    path {
        fill: var(--accent-color); }

    // animation: rotate-logo 5s linear infinite

    &:hover {
        svg {
            animation: rotate-logo 2s linear infinite; } } }

@keyframes rotate-logo {
    0% {
        transform: rotate3d(0, 1, 0, 0deg); }
    50% {
        transform: rotate3d(0, 1, 0, 180deg); }
    100% {
        transform: rotate3d(0, 1, 0, 0deg); } }


// ! ---------- HEADER PLACEHOLDER


// Simulate height of one row of buttons

.header-placeholder {
    @extend %header-typography; }

.header-placeholder {
    visibility: hidden;
    span {
        @extend %button;
        @extend %button--large;
        display: block;
        @include grid-gutter-margin(top bottom, $factor: 1/2);
        @include grid-gutter-margin(bottom, $factor: 1/2);

        &::after {
            content: "\a0\a0"; } } } // nbsp



// ! ---------- NAVIGATION


/* critical:start */

.filter-list {

    .button {
        @extend %button--soft-hover; }

    .button,
    .select-wrapper {
        margin-bottom: $button-margin;
        @include layout--map-always-visible {
            @include grid-gutter-margin(bottom, $factor: 1/2); }
        @include breakpoint(large) {

            transform: translateY(-100%);
            opacity: 0;

            .is-ready & {
                transform: none;
                opacity: 1; }

            @for $i from 1 to 8 {
                .is-animating &:nth-child(#{$i}) {
                    transition-delay: $splash-delay + ($transition-duration * $i); } } } }

    .button,
    .select-label {
        @extend %button--large; }

    .is-disabled {
        pointer-events: none; } }

/* critical:end */

.filter-list__inner {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    @if $scrollable-filter-list {
        @include layout--scrollable-filter-list {
            white-space: nowrap;
            overflow-x: auto;
            @include grid-gutter-margin(left right, $factor: -1);
            @include grid-gutter-padding(left right);
            width: 100%; // 100vw
            flex-wrap: nowrap;

            @if false {
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw; } } } }


.view-toggle {
    margin-bottom: $button-margin;
    @include layout--map-always-visible {
        display: none; } }

.view-reset,
.search-toggle {
    @extend %neutral-button;
    align-self: center;
    @include layout--map-always-visible {
        align-self: flex-start; }

    &:hover svg {
        transition: transition(transform, $factor: 3, $easing: ease-in-out); }

    span {
        @extend %hide-visually; }

    path {
        transition: transition(fill);
        fill: palette('text'); }

    &:hover path {
        fill: var(--accent-color); }

    @include layout--map-always-visible {
        height: $fontsize-xl * 1.75; } } // match nav font size

.search-toggle {
    svg {
        transform: rotate(-45deg) rotate(45deg); }
    &:hover {
        svg {
            transform: rotate(-45deg) rotateY(180deg) rotate(45deg); } } }

.view-reset {
    &:hover svg {
        transform: rotate(360deg); } }

.search-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: palette('background');

    pointer-events: none;
    opacity: 0;
    transition: transition(opacity);
    .is-search-visible & {
        pointer-events: auto;
        opacity: 1; }

    .input-wrapper {
        width: 100%;
        @include breakpoint(all only) {
            padding-top: .5em; } }

    label,
    button[type='submit'],
    .search-form__header {
        @extend %hide-visually; }

    input,
    .input-placeholder {
        display: inline-block;
        font-size: $fontsize-xxl * 0.75;
        letter-spacing: -.03em;
        line-height: 1;
        overflow: hidden;
        max-width: calc(100% - 1.5em);
        @include grid-gutter-margin(left, $factor: 1/2);
        padding: 0;

        @include breakpoint(small) {
            font-size: $fontsize-xxl; } }

    input::placeholder {
        letter-spacing: -.03em;
        line-height: 1;
        line-height: normal; }

    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 2em; }

    .input-placeholder {
        white-space: pre;
        visibility: hidden; }


    // Clear search form

    button[type='reset'] {

        @extend %neutral-button;
        width: 1.5em;
        height: 1.5em;
        position: relative;
        background: palette('text');
        color: palette('background');
        border-radius: 100%;
        top: -1.2em;
        left: 1em;
        transform: scale(.6);
        border: .25em solid palette('text');

        @include breakpoint(small) {
            transform: scale(.67); }

        span {
            @extend %hide-visually; }

        &::after,
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            height: 100%;
            width: 2px;
            transform: translate(-50%, -50%) rotate(45deg);
            background: palette('background');
            z-index: 1; }
        &::before {
            transform: translate(-50%, -50%) rotate(-45deg); }

        &:hover {
            background-color: var(--accent-color);
            border-color: var(--accent-color); } }

    // Show when input filled

    input:valid + button[type='reset'] {
        pointer-events: auto;
        opacity: 1; } }


.location {}


.result-list {
    cursor: default; }

.result-list__introduction {}

.result-list__group {
    width: 100%;
    margin-bottom: $result-group-margin; }

.result-list__item,
.result-list__introduction {
    @extend %result-item-margin; }

.result-list__item--status {
    color: palette('text-light'); }

.result-list__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    @include layout--map-always-visible {
        max-width: $max-text-width; }
    font-size: $fontsize-l;
    position: sticky;
    top: -1px;
    z-index: $z-index-list-header;
    padding-top: 1px;
    padding-bottom: .15em;
    background: palette('background');
    color: var(--accent-color);
    margin-top: 3em;

    .result-list__group:first-child & {
        margin-top: 0; }

    * {
        flex: 1 0;

        &:last-child {
            text-align: right; } } }

.result-list__header + .result-list__group {
    margin-top: $one-line-height; }

.list-introduction {
    color: var(--category-color);
    max-width: $max-text-width;
    padding-bottom: $one-line-height; }

.list-introduction__title {
    margin-bottom: .25em;
    font-weight: bold;
    font-size: $fontsize-l;
    text-transform: uppercase; }

.list-introduction__link {
    margin-top: $one-line-height;
    pointer-events: none;

    &::before {
        display: inline-block;
        padding-right: .5em;
        content: '\E804';
        font-family: $font-icons;
        font-size: .9em; }

    a {
        text-decoration: underline;
        pointer-events: auto; } }

.location__info {
    max-width: $max-text-width;
    margin-bottom: .2em;
    .result-list & {
        @extend %result-item-margin--thin;
        cursor: pointer; }

    .location:hover &,
    &.is-highlighted {
        color: var(--accent-color); } }

.location__label {
    span {
        margin-bottom: .5em; } }

$location-label-width:  1.9rem;
$location-label-height: 1.8rem * 0.65;
$location-label-font:   $location-label-height * 0.55; // $fontsize-xs
$location-label-font-small:   $location-label-height * 0.5; // $fontsize-xs
$location-label-font-xsmall:   $location-label-height * 0.45; // $fontsize-xs

.location-label {
    position: relative;
    line-height: 1;

    span {
        display: inline-block;
        width: $location-label-width;
        height: $location-label-height;
        background: palette('text');
        color: palette('background');
        border: .1em solid transparent;
        border-radius: 100%;
        font-size: $location-label-font;
        font-weight: bold;
        line-height: $location-label-height * 0.9;
        text-align: center;
        user-select: none;
        transition: transition(background color border box-shadow opacity transform);
        @if $tabular-numbers-in-location-label {
            @extend %tabular-numerals; }

        .firefox & {
            line-height: $location-label-height * .91; }

        .full-color-overlay & {
            background: var(--overlay-text-color); } }

    // Smaller font for wider labels
    &[data-length='2'] {
        font-size: $location-label-font-small; }
    &[data-length='3'] {
        font-size: $location-label-font-xsmall;
        span {
            @if $tabular-numbers-in-location-label {
                @extend %proportional-numerals; } } }

    // Different style by location category

    .location-type--institution & span,
    &.location-type--institution span {
        background: palette('background');
        color: palette('text');
        border-color: palette('text');
        .full-color-overlay & {
            color: var(--overlay-text-color);
            border-color: var(--overlay-text-color); } }


    // Hover / highlight: change background

    .location:hover & span,
    .location__info.is-highlighted & span {
        background-color: var(--accent-color);
        border-color: var(--accent-color);
        color: palette('background'); }

    // Slightly smaller in location info components

    .location__info & span {
        transform: scale3d(.9, .9, 1);
        transform-origin: 0% 100%;
        font-size: $location-label-font * 1.125; }

    .location-detail .location-type--gallery & span {
        color: var(--accent-color); }
    .location-detail .location-type--institution & span {
        background: var(--accent-color); }

    // Slight shadow on map to visually separate overlapping markers

    .map-marker &.location-type--gallery {
        span {
            box-shadow: 0 0 0 .1em rgba(palette('background'), 0.75); } }

    .map-marker.is-hovered &.location-type--gallery,
    .map-marker.is-active &.location-type--gallery, {
        span {
            box-shadow: 0 0 0 .1em rgba(palette('background'), 0.25); } }

    @include breakpoint(large) {
        span {
            .is-not-ready .map-marker &,
            .is-animating .map-marker & {
                opacity: 0;
                transform: translateY(-50vh); }

            .is-ready .map-marker & {
                opacity: 1;
                transform: none; }

            @for $i from 1 to 60 {
                .is-animating .map-marker:nth-child(#{$i}) & {
                    transition-delay: $splash-delay + ($transition-duration * $i/5); } }

            .is-ready:not(.is-animating) & {
                transition-delay: 0 !important; } } } }

.location__title {
    display: block;
    font-size: $fontsize-l;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
    transition: transition(color);
    padding-right: .4em; }

.location__distance,
.location__closing {
    display: inline-block;
    color: palette('text-light');
    font-size: $fontsize-s;
    letter-spacing: .02em;

    span {
        @extend %hide-visually; } }

.location__distance {
    margin-right: 1em; }

.location__status {
    margin-top: .35em;
    .icon {
        display: inline-block;
        transform: scale(.9);
        transform-origin: 0% 50%; } }

.location__directions {
    display: inline-block;
    margin-right: 1.35em;
    min-width: 4em; }

.location__opening-status {
    display: inline-block;
    cursor: pointer;
    padding-right: .8em;
    position: relative;

    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(0, -50%) scaleX(0.6);
        content: '';
        width: 0;
        height: 0;
        border-top: .35em solid;
        border-left: .35em dashed transparent;
        border-right: .35em dashed transparent;
        border-bottom: 0;
        display: inline-block;
        overflow: hidden;
        transition: transition(transform);

        .is-opening-times-panel-visible & {
            transform: translate(0, -50%) scaleX(0.6) rotate(180deg); } } }


.location__contact {
    margin-top: .8em; }

.location__actions,
.location__links {
    margin-top: 1.25em;
    .button {
        @extend %button--small;
        @extend %button--full-hover; } }

.location__actions + .location__links {
    margin-top: 1.1em; }

.location__links {
    padding-bottom: .25em;
    .button {
        &:hover {
            color: var(--accent-color); } } }

.location__actions {
    .button {
        @extend %button--highlighted;
        color: var(--accent-color);
        &:hover {
            color: var(--accent-color); } }

    @include layout--map-always-visible {
        display: none; } }

.location__exhibitions {
    margin-top: $one-line-height * 2;
    > h3 {
        margin-bottom: $one-line-height; }

    .location__exhibition + h3 {
        margin-top: $one-line-height * 2; } }

.location__exhibition {
    @extend %result-item-margin; }

.location-detail {}

.location-detail__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column; }

.location-detail__navigation {
    flex: 0 0;
    position: relative;
    user-select: none;
    @include layout--map-as-overlay {
        min-height: 2.5em; } }

.location-detail__content {
    flex: 1 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include grid-gutter-padding(left right bottom, $factor: 1/2); }

.location-detail__prev,
.location-detail__next {
    position: absolute;
    top: 0;
    left: 0;
    @extend %arrow-button;
    @extend %no-tap-highlight;
    transition: transition(opacity);

    &.is-disabled {
        pointer-events: none;
        opacity: .2; } }

.location-detail__next {
    @extend %arrow-button--right; }

.location-detail__close {
    @extend %close-button; }

.location__contact {
    white-space: pre-wrap; }

.location__hours {
    opacity: 0;
    pointer-events: none;
    max-height: 0;
    position: relative;
    transition: transition(opacity max-height);

    .is-opening-times-panel-visible & {
        opacity: 1;
        max-height: 11 * $one-line-height;
        pointer-events: auto; } }

.location__hours-panel {
    padding: .8em 0; }

.hours-table {

    thead {
        display: none; }
    th, td {
        &:nth-child(1) {
            padding-right: 1em; }
        &:nth-child(2),
        &:nth-child(3) {
            @if $tabular-numbers-in-hours-table {
                @extend %tabular-numerals; } }
        &:nth-child(2) {
            white-space: pre;
            text-align: right;
            &::after {
                content: ' –'; } }
        &:nth-child(3) {
            white-space: pre;
            &::before {
                content: ' '; } } } }

.exhibition {
    max-width: $max-text-width; }

.exhibition__title {
    font-style: italic; }

.exhibition__artists {}

.exhibition__category,
.exhibition__space,
.exhibition__overline,
.exhibition__subline, {
    &:empty {
        display: none; } }

.exhibition__category,
.exhibition__overline, {
    .result-list & {
        color: var(--category-color); } }

.exhibition__category,
.exhibition__space, {
    font-size: $fontsize-xs;
    margin-bottom: .2em; }

.result-list .exhibition__dates {
    .is-soon {
        color: var(--accent-color); }
    .is-search-visible & .is-soon {
        color: inherit; } }

.exhibition__links {
    font-size: $fontsize-s;
    line-height: $lineheight-l;
    color: palette('text-light');

    .location-detail & {
        color: currentColor; }

    display: table;
    list-style: none;

    li {
        display: table-row;
        transition: transition(color opacity);
        pointer-events: none;

        .location-detail & {
            opacity: .6; }

        &::before {
            display: table-cell;
            padding-right: .5em;
            content: '\E804';
            font-family: $font-icons;
            font-size: .9em; }
        &:hover {
            color: currentColor;
            opacity: 1 !important;
            .result-list & {
                color: var(--accent-color); } } }

    a {
        text-decoration: underline;
        transition: none;
        pointer-events: auto; } }




// base
.result {
    backface-visibility: hidden;
    z-index: 1; }

// moving
.result-move {
    transition: all 350ms ease-in-out 50ms; }

// appearing
.result-enter-active {
    transition: all 250ms ease-out;
    opacity: 0; }


/* disappearing */
.result-leave-active {
    transition: all 150ms ease-in;
    position: absolute;
    top: 100vh;
    z-index: 0; }

// appear at / disappear to
.result-enter {
    transition: all 150ms ease-in; }

.result-enter,
.result-leave-to {
    opacity: 0; }



.fade-enter-active, .fade-leave-active {
    transition: transition(opacity); }

.fade-enter, .fade-leave-to {
    opacity: 0; }


@keyframes map-fade {
    100% {
        opacity: .25; } }


.map-marker {
    cursor: pointer;
    font-family: $font-stack-default;
    transform: translate(-50%, -50%);
    transition: transition(opacity, $factor: 1/2);

    &.is-highlighted .location-label span {
        animation: map-marker-blink .8s ease-in-out infinite alternate; }

    &.is-highlighted .location-label.location-type--gallery span {
        animation: map-marker-blink--default 1s infinite; }
    &.is-highlighted .location-label.location-type--institution span {
        animation: map-marker-blink--light 1s infinite; }

    &.is-hovered .location-label span {
        background: var(--accent-color);
        border-color: var(--accent-color);
        color: palette('background'); }

    &.is-active .location-label span {
        background: var(--accent-color);
        border-color: var(--accent-color);
        color: palette('background'); }

    &.is-masked {
        opacity: 0;
        pointer-events: none; } }

@keyframes map-marker-blink--default {
    0%, 50% {
        background: var(--accent-color); }
    51%, 100% {
        background: palette('text'); } }

@keyframes map-marker-blink--light {
    0%, 50% {
        background: var(--accent-color);
        border-color: var(--accent-color);
        color: palette('background'); }
    51%, 100% {
        background: palette('background');
        border-color: palette('text');
        color: palette('text'); } }

.map-control-wrapper {
    @include grid-gutter-padding(top left right bottom, $factor: 1/2); }

.map-control {
    position: relative;
    width:  2.1rem; // 1.4rem
    height: 2.1rem; // 1.4rem
    border-radius: 50%;
    background: palette('background');
    transition: transition(background color border box-shadow);
    border: $button-border;
    box-shadow: $button-shadow;
    cursor: pointer;
    transform: scale(0.75);

    [data-whatintent="mouse"] &:hover {
        background: palette('text');
        box-shadow: none; } }

.map-control + .map-control, {
    margin-top: .1rem; }

.map-control + .map-control-group,
.map-control-group + .map-control-group,
.map-control-group + .map-control {
    margin-top: 1rem; }

.map-control--zoom-in,
.map-control--zoom-out {

    &::before,
    &::after {
        content: '';
        height: $button-border-width;
        width: 60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: palette('text');
        box-shadow: 0 0 0 $button-shadow-width;
        transition: transition(background color box-shadow); }


    &::before {
        transform: translate(-50%, -50%); }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg); }

    [data-whatintent="mouse"] &:hover {
        &::before,
        &::after {
            background: palette('background');
            color: palette('background'); } } }

.map-control--zoom-out {
    &::after {
        display: none; } }

.map-control--locate {

    &::before,
    &::after {
        content: '';
        height: $button-border-width;
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: palette('text');
        box-shadow: 0 0 0 $button-shadow-width;
        transition: transition(background color box-shadow);
        z-index: 0; }


    &::before {
        transform: translate(-50%, -50%); }
    &::after {
        transform: translate(-50%, -50%) rotate(90deg); }

    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 1;

        &::before,
        &::after {
            content: '';
            height: 40%;
            width: 40%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: palette('background');
            transition: transition(background color box-shadow border);
            border-radius: 50%; }

        &::before {
            border: $button-border;
            box-shadow: $button-shadow; }

        &::after {
            width: 20%;
            height: 20%;
            background: palette('text');
            display: none; } }

    .has-triggered-watching-user-position & {
        background: palette('text');
        box-shadow: none !important;
        animation: 1.5s user-position-control-blink linear infinite;

        &::before,
        &::after {
            background: palette('background');
            color: palette('background'); }

        span::before {
            background: palette('text');
            color: palette('background');
            border-color: palette('background');
            animation: 1.5s user-position-control-blink--inner linear infinite; } }


    [data-whatintent="mouse"] &:hover,
    .is-watching-user-position & {
        background: palette('text');
        box-shadow: none !important;

        &::before,
        &::after {
            background: palette('background');
            color: palette('background'); }

        span::before {
            background: palette('text');
            color: palette('background');
            border-color: palette('background'); } }

    .is-watching-user-position & {
        background: var(--accent-color);
        border-color: var(--accent-color);
        span::before {
            background: var(--accent-color); } } }


@keyframes user-position-control-blink--inner {
    0%, 50% {
        background: var(--accent-color); }
    51%, 100% {
        background: palette('text'); } }

@keyframes user-position-control-blink {
    0%, 50% {
        background: var(--accent-color);
        border-color: var(--accent-color); }
    51%, 100% {
        background: palette('text');
        border-color: palette('text'); } }

#map-marker-user,
#map-marker-radius {
    pointer-events: none;
    cursor: default; }

.user-position-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: var(--accent-color);
        animation: position-marker-scale 2s ease-in-out infinite alternate;
        box-shadow: 0 2px 3px 1px rgba(0,0,0,.2); } }

.user-position-accuracy {
    width: 400%;
    height: 400%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0,0,0, .1);
    border-radius: 50%;
    z-index: -1;
    opacity: 0; }

@keyframes position-marker-scale {
    0% {
        transform: translate(-50%, -50%) scale3d(1,1,1); }
    100% {
        transform: translate(-50%, -50%) scale3d(.85,.85,1); } }

.user-position-radius-text {
    color: var(--accent-color);
    font-size: $fontsize-s;
    transform: translateY(75%); }

.promotion {
    width: 100%;
    max-width: 100%;
    margin-bottom: $result-group-margin;

    .highlight {
        transition: transition(opacity);
        -webkit-transform: translate3d(0,0,0);
        opacity: 0; }
    &:hover {
        .highlight {
            opacity: 1; } } }
