// ! ---------- LAYOUT

/* critical:start */

body {
    overflow: hidden; }

.static-content {
    @extend %hide-visually; }

/* critical:end */

.page {

    /* critical:start */

    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;

    opacity: 0;
    transition: transition(opacity);

    /* critical:end */

    // This effectively hides all content until non-critical CSS is loaded
    // even if the app is ready

    &.is-ready {
        opacity: 1; } }



.page__header {

    /* critical:start */

    flex-shrink: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @include grid-gutter-padding(top left right, $factor: 1/2);

    @include layout--map-as-overlay {
        flex-wrap: wrap;
        padding-bottom: $result-group-margin * 1/3; }

    /* critical:end */

    position: relative;
    background: palette('background');

    .filter-list {
        order: 4;
        @extend %header-typography;

        @include layout--map-always-visible {
            order: 1;
            width: 100%;
            @include grid-gutter-margin(right, $factor: 1/2); } }

    .view-reset,
    .search-toggle {
        order: 2;
        @include layout--map-always-visible {
            @include grid-gutter-margin(bottom, $factor: 1/2); } }

    .view-reset {
        margin-left: auto; }

    .search-toggle {
        order: 3; }

    .search-toggle {
        margin-left: .75em;
        @include layout--map-always-visible {
            margin-left: 1em; } }

    .logo {
        order: 4;
        margin-left: .75em;
        @include layout--map-always-visible {
            margin-left: 1.5em; } }

    .search-form {
        z-index: $z-index-search; }

    .view-toggle {
        @extend %header-typography;
        width: 50%;
        order: 1; }

    .click-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        background: palette('background');
        transition: transition(opacity);
        opacity: 0;
        z-index: $z-index-detail - 1;
        .is-location-detail-visible & {
            opacity: .5;
            pointer-events: auto; } } }


.page__content {

    /* critical:start */

    flex: 1 1 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    position: relative;

    .result-list,
    .map {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        transition: transition(transform, $factor: 2, $easing: ease-out); }

    .result-list {

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        @include grid-gutter-padding(left right, $factor: 1/2);

        @include breakpoint(small down) {
            .is-map-visible & {
                transform: translateX(-100%); } }

        @include breakpoint(medium) {
            width: percentage(1/2);
            left: percentage(1/2); }

        @include breakpoint(large) {
            width: percentage(1/3);
            left: percentage(2/3);
            transition: transition(opacity);
            opacity: 0;

            .has-initial-animations & {
                transition: transition(transform opacity, $factor: 3, $easing: ease-out);
                transform: translateX(33.3vw); }
            .is-ready & {
                opacity: 1; }
            .has-initial-animations .is-ready & {
                transform: none; }
            .has-splash-screen .is-ready & {
                transition-delay: $splash-delay; } } }


    .map {

        @include breakpoint(small down) {
            transform: translateX(100%);
            // Don't hide completely to avoid Google Map never loading
            // Google Maps JS in 3.52.2 started not loading the map if the container is out of view
            // so we need to make sure it's always at least slightly in view during load time
            .is-not-ready & {
                transform: translateX(90%); }
            .is-map-visible & {
                transform: none; } }

        @include breakpoint(medium) {
            width: percentage(1/2); }

        @include breakpoint(large) {
            width: percentage(2/3); } }


    .map__map {
        position: absolute;
        top: 0;
        @include grid-gutter-property(left, $factor: 1/2);
        @include grid-gutter-property(right, $factor: 1/2);
        @include grid-gutter-property(bottom, $factor: 1/2); }

    /* critical:end */

    .result-status {
        width: 100%; } }

.page__location-detail {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: $z-index-detail;
    overflow: hidden;

    width: percentage(1);
    @include breakpoint(medium) {
        width: percentage(1/2); }
    @include breakpoint(large) {
        width: percentage(1/3); }

    background: var(--accent-color);
    color: var(--overlay-text-color);

    transition: transition(transform box-shadow, $factor: 1.5, $easing: ease-in-out);
    transform: translate3d(100%, 0, 0);
    .is-location-detail-visible & {
        transform: translate3d(0, 0, 0);
        @extend %overlay-shadow-left; } }



/* critical:start */

.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index-splash;
    background-color: var(--accent-color);
    color: var(--accent-color);
    transition: transition(opacity, $factor: 2);
    transition-delay: $transition-duration * $splash-duration;
    opacity: 1;
    user-select: none;
    perspective: $foldout-perspective;

    display: none;
    .has-splash-screen & {
        display: block; }

    .is-ready + & {
        pointer-events: none;
        opacity: 0; } }

.splash-screen__content,
.splash-screen__fold,
.splash-screen__bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: transition(transform background, $easing: ease-in-out, $factor: $splash-duration);

    width: 100%;
    @include breakpoint(landscape) {
        @include breakpoint(medium) {
            width: 50%;
            left: 25%; }
        @include breakpoint(large) {
            width: 33.3333%;
            left: 33.3333%; } }

    background: palette('background');
    transform-style: preserve-3d;
    backface-visibility: hidden; }

.splash-screen__bg {
    @include overlay-shadow-left-right;
    z-index: 0;

    width: 100%;
    @include breakpoint(landscape) {
        @include breakpoint(medium) {
            width: 50%;
            width: calc(50% + 2px);
            left: 25%;
            left: calc(25% - 1px); }
        @include breakpoint(large) {
            width: 33.3333%;
            width: calc(33.3333% + 2px);
            left: 33.3333%;
            left: calc(33.3333% - 1px); } }

    background: palette('background-dark');
    transition: transition(background, $easing: ease-in-out, $factor: $splash-duration);
    border: 1px solid palette('background');
    border-width: 0 1px;
    .is-ready+.splash-screen & {
        background: palette('background'); } }

.splash-screen__content,
.splash-screen__fold {

    // 3d backfaces

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        background: palette('background');
        transform: rotateY(180deg);
        transform-style: preserve-3d;
        backface-visibility: hidden; } }

.splash-screen__content {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;

    transform: rotateY(-180deg);
    transform: rotateY(0deg);
    transform-origin: 0% 0%;

    @include grid-gutter-padding(left right, $factor: 1/2);
    @include grid-gutter-padding(bottom, $factor: 1);

    .is-ready+.splash-screen & {
        transform: rotateY(0deg);
        transform: rotateY(-180deg); } }

.splash-screen__fold {
    transform: rotateY(180deg);
    transform: rotateY(0deg);
    transform-origin: 100% 0%;
    background: palette('background-darker');
    z-index: 1;
    transition-delay: $transition-duration * $splash-duration / 2.5; // 4

    .is-ready+.splash-screen & {
        transform: rotateY(0deg);
        transform: rotateY(180deg);
        background: palette('background'); } }

/* critical:end */

.page__info {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: $z-index-info;
    overflow: hidden;

    pointer-events: none;
    .is-info-panel-visible & {
        pointer-events: auto; } }

.info-panel {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: transition(background, $factor: 3);
    perspective: $foldout-perspective;
    color: var(--accent-color);

    .is-info-panel-visible & {
        background: rgba(palette('background'), .7); } }

.info-panel__content {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    width: percentage(1);
    @include breakpoint(landscape) {
        @include breakpoint(medium) {
            width: percentage(1/2); }
        @include breakpoint(large) {
            width: percentage(1/3); } }

    background: palette('background');
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    transition: transition(transform box-shadow, $factor: 3, $easing: ease-in-out);
    transform: translate3d(100%, 0, 0);
    transform: rotateY(180deg);
    transform-origin: 100% 50%;
    transform-style: preserve-3d;
    backface-visibility: hidden;

    .is-info-panel-visible & {
        transform: translate3d(0, 0, 0);
        transform: none;
        @extend %overlay-shadow-left; }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include grid-gutter-padding(left right, $factor: 1/2);
    @include grid-gutter-padding(bottom, $factor: 1);

    // 3d backface

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        background: palette('background');
        transform: rotateY(180deg);
        transform-style: preserve-3d;
        backface-visibility: hidden; } }


/* critical:start */

.logo-column,
.info-panel__main {
    flex: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100vh;

    @include grid-gutter-padding(top, $factor: 1/2);

    .needs-vh-correction & {
        flex-basis: calc(100vh - var(--vh-offset) ); } }

.logo-column__logo,
.info-panel__logo {
    flex: 1 0;
    position: relative;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    path {
        fill: var(--accent-color); } }

.logo-column__title,
.info-panel__title {
    flex: 0 0;
    font-size: $fontsize-xl-xxl;
    margin-top: 1.5em;
    @include grid-gutter-padding(bottom, $factor: 1/2);
    span {
        position: relative;
        top: .2em; }

    // Avoid flash of unstyled fonts

    .splash-screen & {
        font-family: $font-stack-fallback; } }

/* critical:end */

.info-panel__imprint {
    margin-top: $one-line-height;
    .firefox &,
    .ms & {
        @include grid-gutter-padding(bottom, $factor: 1); } }

.info-panel__close {
    @extend %close-button;
    z-index: $z-index-info+1; }
